<template>
  <main class="main" v-if="page">
    <section class="container">
      <BreadcrumbsComponent :title="page.title" />
      <ul class="vacancy">
        <li class="vacancy__item">
          <a href="#" class="vacancy__item-header--open vacancy__item-header">
            <h3 class="vacancy__item-title">{{ page.title }}</h3>
            <span class="vacancy__item-subtitle">З/п: {{ page.wage }}</span>
            <span class="vacancy__item-subtitle">Город: {{ page.city }}</span>
          </a>
          <div class="vacancy__item-description">
            <EditorJSComponent :text="getVacancyText(page)" />
          </div>
        </li>
      </ul>
    </section>
  </main>
  <NotFound v-else />
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import NotFound from "../PageNotFound.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "VacancyList",
  data() {
    return {
      activeVacancy: 0,
    };
  },
  components: { EditorJSComponent, NotFound, BreadcrumbsComponent },
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_VACANCY_PAGE", route.params.url);
  },
  computed: {
    page() {
      if (this.$store.state.vacancy_page && this.$store.state.vacancy_page.vacancy) {
        return this.$store.state.vacancy_page.vacancy;
      } else {
        return undefined;
      }
    },
  },
  methods: {
    getVacancyText(v) {
      if (v.text) {
        let haveError = false;
        try {
          JSON.parse(v.text);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(v.text);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: v.text },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
    setActiveVacancy(index) {
      if (this.activeVacancy === index) {
        this.activeVacancy = null;
        return;
      }
      this.activeVacancy = index;
    },
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    if (this.$store.state.vacancy_page && this.$store.state.vacancy_page.vacancy) {
      data = this.$store.state.vacancy_page.vacancy;
    }
    let img = this.getImg(data) || "";
    const title = data.meta_title || data.title;
    let metaName = this.page.title;
    let metaFullPath = this.$route.fullPath;

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в {city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaName,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в {city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в {city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в {city}`),
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/vacancy.styl"
</style>
